import React, {useEffect, useState} from 'react';
import {Modal} from 'antd';
import {EmailPreview} from '../components/TicketGroup/View/EmailPreview';
import {getClientMagicLink, getMagicLinkPreview} from '../queries/ticketGroup';
import {EmailPreviewT} from '../types/tickets';

export const useClientShare = (email?: string, groupId?: number) => {
  const [magicLink, setMagicLink] = useState<string | undefined>();
  const [emailPreview, setEmailPreview] = useState<EmailPreviewT | undefined>();

  const fetchMagicLink = async () => {
    setMagicLink('');
    if (!email) return;
    try {
      const res = await getClientMagicLink({
        body: {
          email,
          portal: 'groupTicket',
        },
      });
      setMagicLink(res.body?.magicLink);
    } catch (error) {
      console.error('fetch magic link error: ', error);
    }
  };

  const fetchMagicLinkPreview = async () => {
    setEmailPreview(undefined);
    if (!groupId && groupId !== 0) return;
    try {
      const res = await getMagicLinkPreview(String(groupId));
      const preview = res?.body as EmailPreviewT;
      setEmailPreview(preview);
    } catch (error) {
      console.error('fetch email preview error: ', error);
    }
  };

  const openModal = () => {
    if (!emailPreview) return;
    Modal.info({
      title: 'Email Preview Only',
      content: (
        <EmailPreview
          emailBody={emailPreview?.emailBody}
          subject={emailPreview?.subject}
          from={emailPreview?.from}
          to={emailPreview?.to}
        />
      ),
      okButtonProps: {
        style: {
          display: 'none',
        },
      },
      closable: true,
      className: 'preview',
    });
  };

  useEffect(() => {
    fetchMagicLink();
    fetchMagicLinkPreview();
  }, [email]);

  return {magicLink, openModal};
};
