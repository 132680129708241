import React, {useEffect, useState} from 'react';
import {Footer} from '../../ui-kit/AppLayout/Footer';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {ModalLvl} from '../../components/TicketGroup/View/Table';
import {routePaths} from '../../constants/routes';
import {useGetTicketGroup, useSendNotSentEmails, useShareTickets} from '../../hooks/tickets';
import {ShareModal, BulkModal} from '../TicketGroup/ShareModal';
import {CodeAccessTypes} from '../../ui-kit/helpers/codes';
import {openNotifError} from '../../components/Dashboard';
import {Dropdown, Menu, notification} from 'antd';
import {ItemType} from 'antd/lib/menu/hooks/useItems';
import {MenuAction} from '../../components/Dashboard/styles';
import {ButtonMore} from '../../ui-kit/Button';
import {useClientShare} from '../../hooks/share';
import {TicketGroupT} from '../../queries/types';

const StyledFooter = styled(Footer)<{$fixed?: boolean}>`
  ${({$fixed}) => $fixed && `position: fixed; bottom: 0; left: 0;`};
`;

type TicketFooterContainerT = {
  onSuccess?: () => void;
};

type InternalTicketFooterContainerT = {
  onSuccess?: () => void;
  onBulk?: () => Promise<void>;
  group?: TicketGroupT;
};

export const TicketFooterContainer: React.FC<TicketFooterContainerT> = ({onSuccess}) => {
  const [modalLvl, setModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const [shareText, setShareText] = useState('');
  const {pathname} = useLocation();
  const isCodePage = pathname.includes(routePaths.viewTicketGroup);

  const id = isCodePage ? pathname.split('/')?.[2] : '';
  const {ticketGroup, refetch} = useGetTicketGroup(id, !isCodePage);
  const onShareSuccess = () => {
    refetch();
    onSuccess?.();
  };
  const {shareTicket} = useShareTickets(id, onShareSuccess);
  const onShareClick = () => setModalLvl(ModalLvl.confirm);
  const onShare = async () => await shareTicket();

  useEffect(() => {
    setShareText(ticketGroup?.outboundStatus === CodeAccessTypes.sent ? 'Reshare Access' : 'Share Access');
  }, [ticketGroup?.outboundStatus]);
  const changeModalLvl = (lvl: ModalLvl) => setModalLvl(lvl);
  const isShare = ticketGroup?.outboundStatus === CodeAccessTypes.sent ? true : false;

  const {magicLink, openModal} = useClientShare(ticketGroup?.clientEmail, ticketGroup?.id);
  return (
    <>
      <ShareModal
        isShare={isShare}
        clientEmail={ticketGroup?.clientEmail}
        clientEmailList={ticketGroup?.clientEmailList}
        clientName={ticketGroup?.clientName}
        modalLvl={modalLvl}
        setModalLvl={changeModalLvl}
        onSuccess={onShare}
      />
      <StyledFooter
        showReshare={true}
        buttonText={shareText}
        $fixed={true}
        onReshare={onShareClick}
        rightAction={<FooterDropdown openModal={openModal} magicLink={magicLink} />}
      />
    </>
  );
};

export const InternalTicketFooterContainer: React.FC<InternalTicketFooterContainerT> = ({onSuccess, onBulk, group}) => {
  const [modalLvl, setModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const [isShared, setIsShared] = useState(false);
  const {pathname} = useLocation();
  const isGroupPage = pathname.includes(routePaths.viewTicketGroup);
  const id = isGroupPage ? pathname.split('/')?.[2] : '';
  const {send} = useSendNotSentEmails([id], openNotifError);
  const onShareClick = () => setModalLvl(ModalLvl.confirm);
  const onShare = async () => {
    await send();
    await onBulk?.();
    onSuccess?.();
    setIsShared(true);
    return {res: true, error: ''};
  };
  const showReshare = isGroupPage && !isShared;
  const changeModalLvl = (lvl: ModalLvl) => setModalLvl(lvl);
  const {magicLink, openModal} = useClientShare(group?.clientEmail, group?.id);

  return (
    <>
      <BulkModal modalLvl={modalLvl} setModalLvl={changeModalLvl} onSuccess={onShare} />
      <StyledFooter
        showReshare={showReshare}
        buttonText={'Bulk Send Tickets'}
        $fixed={showReshare}
        onReshare={onShareClick}
        rightAction={<FooterDropdown magicLink={magicLink} openModal={openModal} />}
      />
    </>
  );
};

const FooterDropdown = ({magicLink, openModal}: {magicLink?: string; openModal?: () => void}) => {
  const onCopy = () => {
    if (!magicLink) return;
    navigator.clipboard.writeText(magicLink);
    notification.success({
      message: `Magic Link copied to clipboard!`,
      placement: 'topRight',
      duration: 1,
    });
  };
  const ddActions: ItemType[] = [
    {key: 1, label: <MenuAction onClick={onCopy}>Copy Magic Link</MenuAction>},
    {key: 2, label: <MenuAction onClick={openModal}>Preview Email</MenuAction>},
  ];

  return (
    <Dropdown overlay={<Menu items={ddActions} />} trigger={['click']} placement="top">
      <ButtonMore />
    </Dropdown>
  );
};

const FooterContainer = () => {
  return null;
};
export default FooterContainer;
