import React from 'react';
import {WithChildren} from '../../types/helpers';
import {AppLayout} from '../../ui-kit/AppLayout';
import {Header} from '../../ui-kit/AppLayout/Header';
import {EmptyHeader} from '../../ui-kit/AppLayout/EmptyHeader';
import {ItemType} from 'antd/es/menu/hooks/useItems';
import {useNavigate} from 'react-router-dom';
import {route} from '../../constants/routes';
import {useLogOut, useViewer} from '../../hooks/auth';
import {getHeaderLinks} from '../../hooks/common';
import Footer from './Footer';
import {getPermissions} from '../../helpers/roles';
import {BrandSwitch} from '../Brand/BrandSwitch';
import {getBrandTheme} from '../../hooks/brand';

export const Layout: React.FC<WithChildren> = ({children}) => {
  const onLogOut = useLogOut();
  const permissions = getPermissions();
  const user = useViewer();
  const navigate = useNavigate();
  const onSettingsClick = permissions.haveSettings ? () => navigate(route.settings.path) : undefined;
  const rightText = `Hello, ${user?.firstName || user?.email}`;
  const leftText = 'GROUP TICKETING PORTAL';
  const ddLinks: ItemType[] = getHeaderLinks(permissions).map((el) => ({
    key: el.key,
    label: <a href={el.href}>{el.label}</a>,
  }));
  const brand = getBrandTheme();

  const footer = <Footer />;
  const header = user?.id ? (
    <Header
      onSettingsClick={onSettingsClick}
      onLogout={onLogOut}
      dropDownLinks={ddLinks}
      rightText={rightText}
      leftText={leftText}
      logoTheme={brand}
    />
  ) : (
    <EmptyHeader />
  );
  return (
    <AppLayout footer={footer} header={header}>
      <BrandSwitch />
      {children}
    </AppLayout>
  );
};
