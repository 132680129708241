// import {MessageResType} from '../ui-kit/Input/FileUpload';
import {EmailsMapT, GroupDetailTableDataT, TicketGroupCreateFormValues, TicketTypeRow} from '../types/tickets';
import {
  createMetricsUsed,
  getAdmissionsFromInitial,
  groupsToTableState,
  ticketsListToTableState,
  ticketStateToArray,
  toAddGuestsBodyValues,
  toCreateTicketGroupData,
  toEmailsMap,
  toSelectDepOptions,
  toUpdateTicketGroupData,
  usedMetricsT,
  validateEmail,
} from '../helpers/tickets';
import {useEffect, useRef, useState} from 'react';
import {
  addGroupGuests,
  checkIsUrlValid,
  createTicketGroup,
  deleteGuest,
  deleteTicketGroup,
  getBrands,
  getEventTemplates,
  getGroupCsv,
  getGroupGuests,
  getListTicketGroups,
  getListTickets,
  getTicketGroup,
  resetGroup,
  resyncTicketGroup,
  sendMultipleEmailsQuery,
  sendNotSentGuestEmailsQuery,
  sendTicket,
  shareTicketGroup,
  updateGuest,
  updateTicket,
  updateTicketGroup,
  uploadGuestEmails,
} from '../queries/ticketGroup';
import {
  getListTicketGroupsRes,
  getTicketGroupRes,
  GuestBodyT,
  guestFieldsT,
  guestItemT,
  guestState,
  MetricsT,
  TicketGroupT,
  ticketGuestT,
} from '../queries/types';
import {SelectOptionT} from '../types/helpers';
import {useGetDepartments} from './settings';
import {checkValidationStatus} from '../components/TicketGroup/Form/CheckValidation';
import {MAX_TICKETS, parseError} from '../helpers/common';
import {getErrorResponse} from '../helpers/helpers';
import {groupTicketTypesForTable} from '../helpers/table';
import {notification} from 'antd';
import {getBrandTheme} from './brand';
import {themes} from '../ui-kit/theme/theme';

export type useCreateTicketsT = {
  onCreate?: (data: TicketGroupCreateFormValues, setModalSuccess: () => void) => Promise<boolean>;
  onUpdate?: (data: TicketGroupCreateFormValues) => Promise<boolean>;
  success?: {
    status?: 'completed' | 'failed';
    total?: number;
  };
  error?: string;
  setError?: (v: string) => void;
  id?: string;
  resetResult?: () => void;
  options: {departments: SelectOptionT[]; brand: SelectOptionT[]; tickets: {label: string; value: string}[]};
};

export type useUploadGuestEmailsT = {
  onUpload?: (values: any) => void;
  success?: {
    status?: 'completed' | 'failed';
    total?: number;
  };
  error?: string;
};

export const useCreateTickets = (onSuccess?: () => void): useCreateTicketsT => {
  const [success, setSuccess] = useState({});
  const [error, setError] = useState('');
  const [id, setId] = useState('');
  const resetResult = () => {
    setError('');
    setSuccess({});
  };
  const {departments} = useGetDepartments();
  const {brandOptions} = useGetBrands();
  const {ticketTypeOptions} = useGetEventTemplates();
  const onCreate = async (data: TicketGroupCreateFormValues, setModalSuccess: () => void) => {
    const body = toCreateTicketGroupData(data);
    console.log('body:', body);

    try {
      await createTicketGroup({body}).then((res) => setId(String(res?.body?.id)));
      onSuccess?.();
      setSuccess({status: 'completed'});
      setModalSuccess?.();
      return true;
    } catch (e) {
      setError('Create Error!');
      const err = JSON.parse(JSON.stringify(e));
      notification.error({message: err?.response?.body?.message});
      console.log(e);
      return false;
    }
  };

  return {
    onCreate,
    success,
    error,
    setError,
    id,
    resetResult,
    options: {departments: toSelectDepOptions(departments), brand: brandOptions, tickets: ticketTypeOptions || []},
  };
};

export const useUpdateTickets = (
  id?: string,
  group?: Partial<TicketGroupT>,
  showError?: (message?: string) => void,
  onSuccess?: () => void,
): useCreateTicketsT => {
  const [success, setSuccess] = useState({});
  const [error, setError] = useState('');
  const resetResult = () => {
    setError('');
    setSuccess({});
  };
  const {departments} = useGetDepartments();
  const {brandOptions} = useGetBrands();
  const {ticketTypeOptions} = useGetEventTemplates();
  const onUpdate = async (data: TicketGroupCreateFormValues, setModalSuccess: () => void) => {
    try {
      if (!id) return false;
      const bodyFields = toUpdateTicketGroupData(data, group);
      console.log('body:', bodyFields);
      await updateTicketGroup({groupId: id, body: bodyFields});
      onSuccess?.();
      setSuccess({status: 'completed'});
      setModalSuccess();
      return true;
    } catch (e) {
      setError('Update Error!');
      setSuccess({});
      const err = JSON.parse(JSON.stringify(e));
      const errorMessage = err?.response?.text && JSON.parse(err?.response?.text)?.message;
      showError?.(errorMessage || '');
      return false;
    }
  };

  return {
    onCreate: onUpdate,
    success,
    error,
    resetResult,
    options: {departments: toSelectDepOptions(departments), brand: brandOptions, tickets: ticketTypeOptions || []},
  };
};

export const useGetTicketGroupsTable = () => {
  //pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [groupLimit, setGroupLimit] = useState(10);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);
  const [metricsLoading, setMetricsLoading] = useState(false);
  const [groups, setTicketGroups] = useState<getListTicketGroupsRes>([]);
  const [nonFilteredTickets, setNonFilteredTickets] = useState<getListTicketGroupsRes>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [currentTab, setCurrentTab] = useState<string>('all');

  const defaultMetricsValues = () => ({
    ticketsUploaded: 0,
    ticketsSent: 0,
    ticketsUsed: 0,
  });
  const [metrics, setMetrics] = useState<MetricsT>(defaultMetricsValues());
  const [fullList, setFullList] = useState<getListTicketGroupsRes>([]);
  const fetch = () => {
    setLoading(true);
    getListTicketGroups({pagination: true, page: pageNumber, limit: groupLimit}).then((res) => {
      const results = res?.body?.results;
      setHasNextPage(res?.body?.next);
      setTotal(res?.body?.total);
      setTicketGroups(results as getListTicketGroupsRes);
      setNonFilteredTickets(results as getListTicketGroupsRes);

      setLoading(false);
    });
  };
  const fetchMetrics = async () => {
    setMetricsLoading(true);
    const res = await getListTicketGroups({pagination: false});
    setFullList(res?.body);
    setMetrics(
      res?.body.reduce((acc: MetricsT, el: TicketGroupT) => {
        acc.ticketsUploaded += el.ticketsUploaded || 0;
        acc.ticketsSent += el.ticketsSent || 0;
        acc.ticketsUsed += el.ticketsUsed || 0;
        return acc;
      }, defaultMetricsValues()),
    );
    setMetricsLoading(false);
  };
  const searchTickets = (v: string) => {
    if (fullList) {
      if (v === '') {
        setIsSearching(false);
        setTicketGroups(nonFilteredTickets);
        return;
      }
      setIsSearching(true);

      if (fullList.some((el) => el.clientName?.toLowerCase().includes(v.toLowerCase()))) {
        setTicketGroups(fullList.filter((el) => el.clientName?.toLowerCase().includes(v.toLowerCase())));
      } else if (fullList.some((el) => el.contactPerson?.toLowerCase().includes(v.toLowerCase()))) {
        setTicketGroups(fullList.filter((el) => el.contactPerson?.toLowerCase().includes(v.toLowerCase())));
      } else if (fullList.some((el) => el.brand?.toLowerCase() === v.toLowerCase())) {
        setTicketGroups(fullList.filter((el) => el.brand?.toLowerCase() === v.toLowerCase()));
      } else {
        setTicketGroups([]);
      }
    }
  };

  useEffect(() => {
    fetch();
  }, [pageNumber, groupLimit]);
  useEffect(() => {
    fetchMetrics();
  }, []);

  const changeTab = (val: string) => {
    setCurrentTab(val);
    setPageNumber(1);

    if (val === 'all') {
      setTotal(fullList?.length);
      setTicketGroups(nonFilteredTickets);
      return;
    }
    const groups = fullList.filter((el) => el.brand === val);
    const total = groups.length;
    setTotal(total);
    return setTicketGroups(groups?.slice(0, groupLimit));
  };
  const changePage = (val: number) => setPageNumber(val);
  const changeLimit = (_: number, val: number) => {
    setGroupLimit(val);
  };
  return {
    tickets: groupsToTableState(groups),
    metrics,
    refetch: fetch,
    loading,
    metricsLoading,
    searchTickets,
    pagination: {
      hasNextPage,
      pageNumber,
      groupLimit,
      changePage,
      changeLimit,
      total,
    },
    isSearching,
    changeTab,
    currentTab,
  };
};

export const useGetTicketGroup = (id?: string, skip?: boolean) => {
  const [ticketGroup, setTicket] = useState<getTicketGroupRes>();
  const [loading, setLoading] = useState(false);
  const fetch = async () => {
    if (!id || skip) return;
    setLoading(true);
    await getTicketGroup({groupId: id})
      .then((res) => {
        setTicket(res?.body as getTicketGroupRes);
        setLoading(false);
      })
      .catch(() => setLoading(false));
    setLoading(false);
  };
  useEffect(() => {
    fetch();
    const interval: NodeJS.Timer = setInterval(() => {
      fetch();
    }, 60000);
    return () => clearInterval(interval);
  }, [id]);

  const ticketTypes = ticketGroup?.ticketTypes;
  const groupedTicketTypes = groupTicketTypesForTable(ticketTypes);
  return {ticketGroup, loading, refetch: fetch, metrics: ticketTypes, groupedTicketTypes};
};

export const useGetListTicketGroup = (id?: string, ticketTypes?: TicketTypeRow[]) => {
  const [ticketGroup, setTicketGroup] = useState<TicketGroupT[]>();
  const [guestInfo, setGuestInfo] = useState<ticketGuestT>();
  const [loading, setLoading] = useState(false);
  const [nonFilteredGuests, setNonFilteredGuests] = useState<ticketGuestT>();

  const fetch = () => {
    if (!id) return;
    setLoading(true);
    getGroupGuests({groupId: id}).then((res) => {
      setGuestInfo(res?.body);
      setNonFilteredGuests(res?.body);
    });
    getListTickets({groupId: id}).then((res) => {
      setTicketGroup(res?.body as getListTicketGroupsRes);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetch();
    const interval: NodeJS.Timer = setInterval(() => {
      fetch();
    }, 60000);
    return () => clearInterval(interval);
  }, [id]);

  const searchTickets = (v: string) => {
    if (guestInfo) {
      if (v === '') {
        setGuestInfo(nonFilteredGuests);
        return;
      }
      const searchValues = v.split(',').map((el) => el.toLowerCase().trim());
      const results: guestItemT[] = [];

      nonFilteredGuests?.guests.forEach((guest) => {
        const guestName = guest?.guestName?.toLowerCase();
        const guestEmail = guest?.guestEmail?.toLowerCase();

        const searched = searchValues.some((sv) => guestName.includes(sv) || guestEmail.includes(sv));
        if (searched) results.push(guest);
      });
      setGuestInfo({...guestInfo, guests: results});
    }
  };

  return {
    ticketGroup,
    tableData: ticketGroup ? ticketsListToTableState(ticketGroup, guestInfo, ticketTypes) : [],
    loading,
    ticketsCount: ticketGroup?.length,
    usedTickets: createMetricsUsed(ticketGroup, ticketTypes),
    refetch: fetch,
    emailsMap: toEmailsMap(ticketGroup),
    searchTickets,
  };
};

export const useDeleteTicketsGroup = (onSuccess?: () => void) => {
  const onDelete = async (groupId: string) => {
    try {
      const res = await deleteTicketGroup({groupId});
      if (res) onSuccess?.();
    } catch (e) {}
  };
  return {onDelete};
};

export const useShareTickets = (groupId?: string, onSuccess?: () => void) => {
  const shareTicket = async () => {
    if (!groupId) return {res: false, error: ''};
    try {
      const res = await shareTicketGroup({groupId});
      if (res?.ok) onSuccess?.();
      return {res: true, error: ''};
    } catch (e) {
      return {res: false, error: parseError(e)};
    }
  };
  return {shareTicket};
};

export const useUploadGuestEmails = (groupIDs: string[], onSuccess1?: () => void) => {
  const [uploadResult, setUploadResult] = useState({});
  const [uploadLoading, setUploadLoading] = useState(false);

  const upload = async (file: any, onSuccess?: () => void) => {
    const groupId = groupIDs[0];
    if (!groupId) return;
    setUploadLoading(true);

    try {
      let result;
      if (groupIDs.length === 1) {
        const res = await uploadGuestEmails({groupId: groupIDs[0], values: file});
        if (res) {
          console.log(res);
          onSuccess?.();
          onSuccess1?.();
          setUploadResult({total: res?.body?.length || 0, success: true});
          result = {total: res?.body?.length || 0, success: true};
        }
      } else {
        let codes = 0;
        const res = await Promise.all(
          groupIDs.map((el) => {
            return uploadGuestEmails({groupId: el, values: file}).then((res) => (codes = codes + (res?.body || 0)));
          }),
        );
        if (res) {
          console.log(res);
          onSuccess1?.();
          onSuccess?.();
          setUploadResult({total: codes, success: true});
          result = {total: codes, success: true};
        }
      }

      setUploadLoading(false);
      return result;
    } catch (e) {
      setUploadLoading(false);
      console.log(e);
      return {error: getErrorResponse(e)};
    }
  };
  return {upload, uploadResult, uploadLoading};
};

export type useSendNotSentEmailsT = {
  send: (onSuccess?: () => void) => Promise<void>;
  loading?: boolean;
};

export const useSendNotSentEmails = (
  groupIDs: string[],
  showError?: (message?: string) => void,
): useSendNotSentEmailsT => {
  const [loading, setLoading] = useState(false);

  const send = async (onSuccess?: () => void) => {
    const groupId = groupIDs[0];
    setLoading(true);
    if (!groupId) return;
    try {
      if (groupIDs.length === 1) {
        const res = await sendNotSentGuestEmailsQuery({groupId});
        if (res) onSuccess?.();
      } else {
        const res = await Promise.all(
          groupIDs.map((el) => {
            return sendNotSentGuestEmailsQuery({groupId: el});
          }),
        );
        if (res) onSuccess?.();
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      const error = JSON.parse(JSON.stringify(e));
      const errorMessage = error?.response?.text && JSON.parse(error?.response?.text)?.message;
      showError?.(errorMessage || '');
    }
  };
  return {send, loading};
};

export type useTicketsActionsT = {
  onDelete?: (ticketId?: string) => Promise<boolean>;
  onUpdate?: (props: {ticketId?: string; email?: string; name?: string}) => Promise<boolean>;
};

export const useTicketsActions = (groupId?: string, onSuccess?: () => void, showError?: (message?: string) => void) => {
  const onDelete = async (guestId?: string) => {
    if (!guestId || !groupId) return false;
    try {
      const res = await deleteGuest({groupId, guestId});
      if (res) onSuccess?.();
      return true;
    } catch (e) {
      return false;
    }
  };

  const onUpdate = async ({ticketId, email, name}: {ticketId?: string; email?: string; name?: string}) => {
    if (!ticketId || !groupId || !email) return false;
    try {
      const body = name ? {guestEmail: email, guestName: name} : {guestEmail: email};
      const res = await updateTicket({groupId, ticketId, body});
      if (res) onSuccess?.();
      return true;
    } catch (e) {
      const error = JSON.parse(JSON.stringify(e));
      const errorMessage = error?.response?.text && JSON.parse(error?.response?.text)?.message;
      showError?.(errorMessage || '');
      return false;
    }
  };

  return {onDelete, onUpdate};
};

export const useSendTicket = (groupId?: string, onSuccess?: () => void) => {
  const onSend = async (ticketId?: string) => {
    if (!groupId || !ticketId) return false;
    try {
      const res = await sendTicket({groupId, ticketId});
      if (res?.ok) onSuccess?.();
      return true;
    } catch (e) {
      return false;
    }
  };
  return {onSend};
};

export type tickerRow = {email?: string; name?: string};
export type ticketState = Record<any, tickerRow>;
export type ticketStateVal = {email?: string; name?: string; key: string};

export type useSetTicketStateT = {
  state: ticketState;
  handleSetEmail: (val: ticketStateVal) => void;
  handleSetName: (val: ticketStateVal) => void;
  reset: () => void;
};

export const useSetTicketState = (): useSetTicketStateT => {
  const [state, setState] = useState<ticketState>({});
  const handleSetEmail = (val: ticketStateVal) =>
    setState((prev) => ({...prev, [val.key]: {...prev[val.key], email: val.email}}));
  const handleSetName = (val: ticketStateVal) =>
    setState((prev) => ({...prev, [val.key]: {...prev[val.key], name: val.name}}));
  const reset = () => setState({});
  return {state, handleSetName, handleSetEmail, reset};
};

type multipleUpdateProps = {
  groupId?: string;
  onSuccess?: () => void;
};

export const useMultipleUpdate = ({groupId, onSuccess}: multipleUpdateProps) => {
  const {onUpdate} = useTicketsActions(groupId);
  const updateAll = async (emails: ticketState) => {
    if (!groupId) return false;
    try {
      const rows = ticketStateToArray(emails);
      const res = await Promise.all(rows.map((el) => onUpdate({ticketId: el?.id, email: el?.email, name: el?.name})));
      if (res) onSuccess?.();
    } catch (e) {
      console.log(e);
    }
  };

  return {updateAll};
};

type useMultipleSendProps = {
  groupId?: string;
  onSuccess?: () => void;
};

export const useMultipleSend = ({groupId, onSuccess}: useMultipleSendProps) => {
  const {onSend} = useSendTicket(groupId);
  const sendAll = async (state: ticketState) => {
    if (!groupId) return false;
    try {
      const rows = ticketStateToArray(state).map((el) => el.id);
      const res = await Promise.all(rows.map((id) => onSend(id)));
      if (res) onSuccess?.();
      onSuccess?.();
    } catch (e) {
      console.log(e);
    }
  };

  return {sendAll};
};

export type guestStateVal = {key: guestFieldsT; value?: string | number};
export type useAddGuestStateT = {
  guestState: guestState;
  guestSetState: (val: guestStateVal) => void;
  guestSetTickets?: (val: guestStateVal) => void;
  reset: () => void;
  onAddGuest: () => Promise<boolean>;
  loadingSave: boolean;
};

export const useAddGuests = (
  metrics?: TicketTypeRow[],
  metricsUsed?: usedMetricsT,
  groupId?: string,
  onSuccess?: () => void,
): useAddGuestStateT => {
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [state, setState] = useState<guestState>({valid: false, validInfo: false});
  const handleChange = (val: guestStateVal) => setState((prev: any) => ({...prev, [val.key]: val?.value}));
  // const totalTickets = getGuestTotalTickets(metrics);
  const number_tickets = metrics
    ?.filter((el) => Number(state?.[el?.ticketType]))
    .map((el) => Number(state?.[el?.ticketType]));
  const sel = number_tickets?.join('');
  const ticketsSum = number_tickets?.reduce((p, n) => p + n, 0) || 0;

  useEffect(() => {
    const validInfo = !!validateEmail(state?.email) && (state.name?.length || 0) > 0;
    let isValid = validInfo && !!sel;
    metrics?.forEach((el) => {
      if (!el?.ticketType) return;
      const total = metrics?.find((m) => m.ticketType === el?.ticketType)?.ticketTypeUploaded || 0;
      const used = Number(metricsUsed?.[el?.ticketType]) || 0;
      if (Number(state?.[el?.ticketType] || 0) + used > total) isValid = false;
      if (ticketsSum > MAX_TICKETS) isValid = false;
    });

    setState((prev: any) => ({...prev, number_tickets: ticketsSum, valid: isValid, validInfo: validInfo}));
  }, [state.name, state.email, sel]);
  const reset = () => setState({valid: false, validInfo: false});

  const onAddGuest = async () => {
    if (!groupId || !state.valid) return false;
    setLoadingSave(true);
    try {
      const body = toAddGuestsBodyValues(state);
      const res = await addGroupGuests({groupId, body});
      if (res) {
        onSuccess?.();
        reset();
        setTimeout(() => {
          onSuccess?.(); //REFETCH TICKET COUNTS with delay
        }, 2500);
      }
      setLoadingSave(false);
      return true;
    } catch (e) {
      console.log(e);
      setLoadingSave(false);

      return false;
    }
  };
  return {guestState: state, guestSetState: handleChange, reset, onAddGuest, loadingSave};
};

export type sendMultipleT = (ids: string[]) => Promise<boolean>;
export type useMultipleSendbyEmailsT = {
  sendMultiple: sendMultipleT;
  loadingSend: boolean;
};
export const useMultipleSendbyEmails = (
  groupId?: string,
  onSuccess?: () => void,
  showError?: (message?: string) => void,
): useMultipleSendbyEmailsT => {
  const [loadingSend, setLoadingSend] = useState<boolean>(false);
  const sendMultiple = async (ids: string[]) => {
    if (!groupId) return false;
    setLoadingSend(true);
    try {
      const body = {ids: ids};
      const res = await sendMultipleEmailsQuery({groupId, body});
      if (res) onSuccess?.();
      onSuccess?.();
      setLoadingSend(false);
      return true;
    } catch (e) {
      console.log(e);
      setLoadingSend(false);
      const error = JSON.parse(JSON.stringify(e));
      const errorMessage = error?.response?.text && JSON.parse(error?.response?.text)?.message;
      showError?.(errorMessage || '');
      return false;
    }
  };

  return {sendMultiple, loadingSend};
};

export type TGetCSV = {
  onDownload: () => Promise<false | undefined>;
  ref: React.MutableRefObject<HTMLElement | null>;
  url: string | undefined;
  name: string | undefined;
};

export const useGetCsv = (groupId?: string) => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const [url, setFileUrl] = useState<string>();
  const [name, setFileName] = useState<string>();

  const onDownload = async () => {
    if (!groupId) return false;
    try {
      const res = await getGroupCsv({groupId});
      const tempUrl = URL.createObjectURL(res.body);
      setFileUrl(tempUrl);
      setFileName(res.headers?.filename || 'enchant-csv-email-template.csv');
    } catch (error) {
      console.log(error);
    }
  };

  return {onDownload, ref, url, name};
};

export const useCheckValidation = (ticketUrl: string, setIsClicked?: (v: boolean) => void, brand?: string) => {
  const [isLoading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [validStatus, setValidStatus] = useState<checkValidationStatus>('none');
  const setStatus = (status: checkValidationStatus) => setValidStatus(status);
  const onSuccess = () => {
    setValidStatus('checked');
    setIsValid(true);
    setLoading(false);
  };
  const onFail = () => {
    setValidStatus('invalid');
    setIsValid(false);
    setLoading(false);
  };

  const onCheck = async () => {
    setIsClicked?.(true);
    setLoading(true);
    try {
      await checkIsUrlValid(ticketUrl, brand);
      onSuccess();
      return true;
    } catch (e) {
      onFail();
      return false;
    } finally {
      setLoading(false);
    }
  };

  return {onCheck, isLoading, isValid, validStatus, setStatus};
};

export const useEditGuest = (
  onUpdateGuest: onUpdateGuestT,
  initialState?: GroupDetailTableDataT,
  metrics?: TicketTypeRow[],
  metricsUsed?: usedMetricsT,
  emailsMap?: EmailsMapT,
  resetId?: () => void,
  handleSend?: () => void,
) => {
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);
  // const [initialEmail, setInitialEmail] = useState<string | undefined>('');
  const [eGuestState, setEditingState] = useState<guestState>({valid: true, validInfo: true});
  const handleEdit = (val: guestStateVal) => setEditingState((prev: any) => ({...prev, [val.key]: val?.value}));

  useEffect(() => {
    const admissions = getAdmissionsFromInitial(initialState);
    setEditingState((prev: guestState) => ({
      ...prev,
      ...admissions,
      name: initialState?.name?.name,
      email: initialState?.email?.email,
    }));
    // setInitialEmail(initialState?.email?.email);
  }, [initialState?.id]);

  const number_tickets = metrics
    ?.filter((el) => Number(eGuestState?.[el?.ticketType]))
    .map((el) => Number(eGuestState?.[el?.ticketType]));
  const sel = number_tickets?.join('');
  const ticketsSum = number_tickets?.reduce((p, n) => p + n, 0) || 0;

  useEffect(() => {
    const validInfo = !!validateEmail(eGuestState?.email) && (eGuestState.name?.length || 0) > 0;
    let isValid = validInfo && sel;
    metrics?.forEach((el) => {
      if (!el?.ticketType) return;
      const count = initialState?.[el?.ticketType as 'admission']?.count;
      const total = metrics?.find((m) => m.ticketType === el?.ticketType)?.ticketTypeUploaded || 0;
      const used = Number(metricsUsed?.[el?.ticketType]) || 0;
      if (Number(eGuestState?.[el?.ticketType] || 0) - Number(count || 0) + used > total) isValid = false;
      if (ticketsSum > MAX_TICKETS) isValid = false;
    });
    setEditingState((prev: any) => ({...prev, number_tickets: ticketsSum, valid: isValid, validInfo: validInfo}));
  }, [eGuestState.name, eGuestState.email, sel]);

  const onSave = async () => {
    setLoadingEdit(true);
    // const ids = emailsMap?.filter((el) => el?.email === initialEmail).map((el) => el?.id || '');
    const bodyData = Object.assign(eGuestState);
    delete bodyData.valid;
    delete bodyData.validInfo;
    const res = await onUpdateGuest(initialState?.id, bodyData, resetId);

    const useAutoSend = false;
    if (res && useAutoSend) {
      await handleSend?.();
      setEditingState({valid: true, validInfo: true});
    }
    if (!res) {
      resetId?.();
      setEditingState({valid: true, validInfo: true});
    }
    setLoadingEdit(false);
  };
  return {eGuestState, handleEdit, onSave, loadingEdit};
};

export type onUpdateTicketsT = (
  ids?: string[],
  email?: string,
  name?: string,
  onSuccess2?: () => void,
) => Promise<boolean>;

export const useUpdateTicketsInfo = (
  groupId?: string,
  onSuccess?: () => void,
  showError?: (message?: string) => void,
) => {
  const onUpdateTickets = async (ids?: string[], email?: string, name?: string, onSuccess2?: () => void) => {
    if (!groupId || !email) return false;
    try {
      await Promise.all(
        ids?.map((el) => updateTicket({groupId, ticketId: el || '', body: {guestEmail: email, guestName: name}})) || [],
      );
      onSuccess?.();
      onSuccess2?.();
      return true;
    } catch (e) {
      const error = JSON.parse(JSON.stringify(e));
      const errorMessage = error?.response?.text && JSON.parse(error?.response?.text)?.message;
      showError?.(errorMessage || '');
      return false;
    }
  };
  return {onUpdateTickets};
};

export const useReSync = (group?: TicketGroupT, onSuccess?: () => void, showError?: (message?: string) => void) => {
  const reSync = async () => {
    if (!group?.id || !group?.orderId) return;
    try {
      const body: any = {ticketureOrderId: String(group?.ticketureOrderId)};
      const res = await resyncTicketGroup({groupId: String(group?.id), body});
      if (res) onSuccess?.();
    } catch (e) {
      const error = JSON.parse(JSON.stringify(e));
      const errorMessage = error?.response?.text && JSON.parse(error?.response?.text)?.message;
      showError?.(errorMessage || '');
    }
  };
  return {reSync};
};

export type onUpdateGuestT = (id?: string, guestBody?: GuestBodyT, onSuccess2?: () => void) => Promise<boolean>;

export const useUpdateGuest = (groupId?: string, onSuccess?: () => void, showError?: (message?: string) => void) => {
  const onUpdateGuest = async (id?: string, guestBody?: GuestBodyT, onSuccess2?: () => void) => {
    if (!groupId || !id || !guestBody?.email) return false;
    try {
      await updateGuest({groupId, guestId: id, body: guestBody});
      onSuccess?.();
      onSuccess2?.();
      return true;
    } catch (e) {
      const error = JSON.parse(JSON.stringify(e));
      const errorMessage = error?.response?.text && JSON.parse(error?.response?.text)?.message;
      showError?.(errorMessage || '');
      return false;
    }
  };
  return {onUpdateGuest};
};

export const useResetGroup = (group?: TicketGroupT, onSuccess?: () => void) => {
  const groupId = group?.id;
  const onReset = async () => {
    if (!groupId && groupId !== 0) return false;
    try {
      const res = await resetGroup({groupId: String(groupId)});
      if (res) onSuccess?.();
      return true;
    } catch (e) {
      return false;
    }
  };
  return onReset;
};

export const useGetBrands = () => {
  const [brands, setBrands] = useState<{key: string; name: string}[]>();

  const fetch = async () => {
    try {
      const res = await getBrands();
      const theme = getBrandTheme();

      const list = res?.body?.filter((el: any) => {
        if (theme === themes.lite) return el?.name?.toLowerCase().includes('jingle');
        return !el?.name?.toLowerCase().includes('jingle');
      });
      setBrands(list);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetch();
  }, []);
  const brandOptions = brands?.map((el) => ({label: el.name, value: el.key})) || [];
  return {brands, brandOptions};
};
export const useGetEventTemplates = () => {
  const [eventTemplates, setEventTemplates] = useState<string[]>();

  const fetch = async () => {
    try {
      const res = await getEventTemplates();
      setEventTemplates(res?.body);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetch();
  }, []);
  const ticketTypeOptions = eventTemplates?.map((el) => ({label: el, value: el}));
  return {eventTemplates, ticketTypeOptions};
};
