import React from 'react';
import {ButtonProps} from 'antd';
import {StyledButton, StyledInfoButton, StyledLink, StyledButtonMore} from './styles';

export const Button: React.FC<ButtonProps & InfoButtonProps> = ({...props}) => <StyledButton {...props} />;

export enum ButtonTypes {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  quaternary = 'quaternary',
  quinary = 'quinary',
  senary = 'senary',
}
export type InfoButtonProps = {
  variant?: ButtonTypes;
};

export const InfoButton: React.FC<ButtonProps & InfoButtonProps> = ({...props}) => <StyledInfoButton {...props} />;

export const LinkButton: React.FC<ButtonProps & InfoButtonProps & {to: string}> = ({to, ...props}) => (
  <StyledLink {...props} to={to}>
    <StyledButton {...props} />
  </StyledLink>
);

const MoreIcon = () => (
  <svg width="5" height="17" viewBox="0 0 5 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="2.96484" cy="2.64453" r="2" fill="#020E16" />
    <circle cx="2.96484" cy="8.64453" r="2" fill="#020E16" />
    <circle cx="2.96484" cy="14.6445" r="2" fill="#020E16" />
  </svg>
);

export const ButtonMore: React.FC<ButtonProps> = ({...props}) => (
  <StyledButtonMore {...props} type={'text'} icon={<MoreIcon />} />
);
