import {CODES_PORTAL_URL, NOTIFICATIONS_PORTAL_URL} from '../constants/env';
import {getToken, getTokenJingle} from './auth';
import {permissionsList} from '../helpers/roles';

export const urlWithToken = (url: string, token: string, expires?: number, token_j?: string) =>
  `${url}?token=${token}&expires=${expires || 604800}&token_j=${token_j || ''}`;

export const getHeaderLinks = (permissions?: permissionsList) => {
  const token = getToken();
  const token_j = getTokenJingle() || '';
  const links = [
    ...(CODES_PORTAL_URL && permissions?.promoAccess
      ? [{key: 1, label: 'Promo Code Portal', href: urlWithToken(CODES_PORTAL_URL, token || '', undefined, token_j)}]
      : []),
    ...(NOTIFICATIONS_PORTAL_URL && permissions?.isSuperAdmin && permissions?.notificationsAccess
      ? [
          {
            key: 2,
            label: 'Notifications Portal',
            href: urlWithToken(NOTIFICATIONS_PORTAL_URL, token || '', undefined, token_j),
          },
        ]
      : []),
  ];
  return links;
};
