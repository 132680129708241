export enum AuthQueryList {
  logout = '/v1/auth/logout',
  authorize = '/v1/auth/authorize',
  getAssociates = '/v1/associates',
}

export const TICKET_ORDER_URL = 'https://enchant.tix-web.d1tix.com/ticket_order/';

export const TicketQueryList = {
  getListTicketGroups: (pagination: boolean, page?: number, limit?: number) =>
    pagination
      ? `/v1/ticketGroup?pagination=${pagination}&page=${page}&limit=${limit}&sortBy=id&sortOrder=DESC`
      : `/v1/ticketGroup?sortBy=id&sortOrder=DESC`,
  createTicketGroup: () => `/v1/ticketGroup`,
  getTicketGroup: (groupId: string) => `/v1/ticketGroup/${groupId}`,
  updateTicketGroup: (groupId: string) => `/v1/ticketGroup/${groupId}`,
  resyncTicketGroup: (groupId: string) => `/v1/ticketGroup/${groupId}/resync`,
  deleteTicketGroup: (groupId: string) => `/v1/ticketGroup/${groupId}`,
  shareTicketGroup: (groupId: string) => `/v1/ticketGroup/${groupId}/share`,
  uploadEmails: (groupId: string) => `/v1/ticketGroup/${groupId}/uploadGuestEmails`,
  sendNotSentGuestEmails: (groupId: string) => `/v1/ticketGroup/${groupId}/sendNotSentGuestEmails`,
  getListTickets: (groupId: string) => `/v1/ticketGroup/${groupId}/ticket`,
  getTicket: (groupId: string, ticketId: string) => `/v1/ticketGroup/${groupId}/ticket/${ticketId}`,
  updateTicket: (groupId: string, ticketId: string) => `/v1/ticketGroup/${groupId}/ticket/${ticketId}`,
  deleteTicket: (groupId: string, ticketId: string) => `/v1/ticketGroup/${groupId}/ticket/${ticketId}`,
  sendTicket: (groupId: string, ticketId: string) => `/v1/ticketGroup/${groupId}/ticket/${ticketId}/send`,
  //new
  getGuests: (groupId: string) => `/v1/ticketGroup/${groupId}/guest`,
  addGuests: (groupId: string) => `/v1/ticketGroup/${groupId}/guest`,
  updateGuest: (groupId: string, guestId: string) => `/v1/ticketGroup/${groupId}/guest/${guestId}`,
  sendMultiple: (groupId: string) => `/v1/ticketGroup/${groupId}/sendMultipleGuestEmails`,
  checkUrl: (url: string, brand?: string) =>
    `/v1/ticketGroup/orderStatus?ticketureOrderId=${url}${brand ? `&brand=${brand}` : ''}`,
  deleteGuest: (groupId: string, guestId: string) => `/v1/ticketGroup/${groupId}/guest/${guestId}`,
  resetGroup: (groupId: string) => `/v1/ticketGroup/${groupId}/guest`,
  getEmailPreview: (groupId: string, guestId: string) => `/v1/ticketGroup/${groupId}/guest/${guestId}/emailPreview`,
  //file
  getCsv: (groupId: string) => `/v1/ticketGroup/${groupId}/guestCsv`,

  getBrands: () => `/v1/brands`,
  getEventTemplates: () => `/v1/eventTemplates`,

  //share
  getClientMagicLink: () => `/v1/auth/getMagicLink`,
  getPreviewMagicLink: (groupId: string) => `/v1/ticketGroup/${groupId}/share?emailPreview=true`,
};

export const SettingsQueries = {
  getPermissions: () => `/v1/permission`,
  createPermission: () => `/v1/permission`,
  updatePermission: (permissionId: string) => `/v1/permission/${permissionId}`,
  deletePermission: (permissionId: string) => `/v1/permission/${permissionId}`,
  getRoles: () => `/v1/role`,
  createRole: () => `/v1/role`,
  updateRole: (roleId: string) => `/v1/role/${roleId}`,
  deleteRole: (roleId: string) => `/v1/role/${roleId}`,
  assignPermissions: (roleId: string) => `/v1/role/${roleId}/assignPermissions`,
  getDepartments: () => `/v1/department`,
  createDepartment: () => `/v1/department`,
  updateDepartment: (departmentId: string) => `/v1/department/${departmentId}`,
  deleteDepartment: (departmentId: string) => `/v1/department/${departmentId}`,
  getUsers: () => `/v1/user`,
  inviteUser: () => `/v1/user`,
  deleteUser: (userId: string) => `/v1/user/${userId}`,
  updateUser: (userId: string) => `/v1/user/${userId}`,
};

export const PDFQueryList = {
  generatePdf: (groupId: string) => `/v1/ticketGroup/${groupId}/generateTicketsPdf`,
  getPdfDownloadUrl: (groupId: string, individual?: boolean) =>
    `/v1/ticketGroup/${groupId}/getTicketsPdfUrlById${individual !== undefined ? `?individualPdfs=${individual}` : ''}`,
};
