import {getQuery, putQuery, deleteQuery, postQuery} from './hooks';
import {PDFQueryList, TicketQueryList} from '../constants/api';
import {
  getTicketGroupReq,
  updateTicketGroupReq,
  createTicketGroupReq,
  deleteTicketGroupReq,
  deleteTicketReq,
  getTicketReq,
  sendTicketReq,
  shareTicketGroupReq,
  updateTicketReq,
  getListTicketsReq,
  uploadGuestEmailsReq,
  sendNotSentGuestEmailsReq,
  AddGuestsReqT,
  updateGuestReq,
  deleteGuestReq,
  generatePdfReq,
  downloadPdfReq,
  getTicketGroupsReq,
  resetGroupReq,
  GetEmailTemplateReq,
} from './types';
import {sendMultipleEmailsQueryReq} from './types/tickets';
import {wrapCreateTicketGroup, wrapUpdateTicketGroup} from '../helpers/tickets';
import {ShareBodyT} from '../types/tickets';

export const getListTicketGroups = async ({pagination, page, limit}: getTicketGroupsReq) =>
  await getQuery(TicketQueryList.getListTicketGroups(pagination, page, limit));

export const createTicketGroup = async ({body}: createTicketGroupReq) =>
  await postQuery(TicketQueryList.createTicketGroup()).send(wrapCreateTicketGroup(body));

export const getTicketGroup = async ({groupId}: getTicketGroupReq) =>
  await getQuery(TicketQueryList.getTicketGroup(groupId));

export const updateTicketGroup = async ({groupId, body}: updateTicketGroupReq) =>
  await putQuery(TicketQueryList.updateTicketGroup(groupId)).send(wrapUpdateTicketGroup(body));

export const resyncTicketGroup = async ({groupId}: updateTicketGroupReq) =>
  await postQuery(TicketQueryList.resyncTicketGroup(groupId)).send({});

export const deleteTicketGroup = async ({groupId}: deleteTicketGroupReq) =>
  await deleteQuery(TicketQueryList.deleteTicketGroup(groupId));

export const shareTicketGroup = async ({groupId}: shareTicketGroupReq) =>
  await postQuery(TicketQueryList.shareTicketGroup(groupId));

export const uploadGuestEmails = async ({groupId, values}: uploadGuestEmailsReq) =>
  await postQuery(TicketQueryList.uploadEmails(groupId)).field('guestEmails', values);

export const sendNotSentGuestEmailsQuery = async ({groupId}: sendNotSentGuestEmailsReq) =>
  await postQuery(TicketQueryList.sendNotSentGuestEmails(groupId));

export const getListTickets = async ({groupId}: getListTicketsReq) =>
  await getQuery(TicketQueryList.getListTickets(groupId));

export const getTicket = async ({groupId, ticketId}: getTicketReq) =>
  await getQuery(TicketQueryList.getTicket(groupId, ticketId));

export const updateTicket = async ({groupId, ticketId, body}: updateTicketReq) =>
  await putQuery(TicketQueryList.updateTicket(groupId, ticketId)).send(body);

export const updateGuest = async ({groupId, guestId, body}: updateGuestReq) =>
  await putQuery(TicketQueryList.updateGuest(groupId, guestId)).send(body);

export const deleteTicket = async ({groupId, ticketId}: deleteTicketReq) =>
  await deleteQuery(TicketQueryList.deleteTicket(groupId, ticketId));

export const sendTicket = async ({groupId, ticketId}: sendTicketReq) =>
  await postQuery(TicketQueryList.sendTicket(groupId, ticketId));

export const getGroupGuests = async ({groupId}: getTicketGroupReq) =>
  await getQuery(TicketQueryList.getGuests(groupId));

export const addGroupGuests = async ({groupId, body}: AddGuestsReqT) =>
  await postQuery(TicketQueryList.addGuests(groupId)).send(body);

export const sendMultipleEmailsQuery = async ({groupId, body}: sendMultipleEmailsQueryReq) =>
  await postQuery(TicketQueryList.sendMultiple(groupId)).send(body);

export const getGroupCsv = async ({groupId}: getTicketGroupReq) =>
  await getQuery(TicketQueryList.getCsv(groupId)).responseType('blob');

export const checkIsUrlValid = async (url: string, brand?: string) =>
  await getQuery(TicketQueryList.checkUrl(url, brand));

export const deleteGuest = async ({groupId, guestId}: deleteGuestReq) =>
  await deleteQuery(TicketQueryList.deleteGuest(groupId, guestId));

export const generatePdf = async ({groupId}: generatePdfReq) => await getQuery(PDFQueryList.generatePdf(groupId));

export const getPdfDownloadUrl = async ({groupId, individual}: downloadPdfReq) =>
  await getQuery(PDFQueryList.getPdfDownloadUrl(groupId, individual));

export const resetGroup = async ({groupId}: resetGroupReq) => await deleteQuery(TicketQueryList.resetGroup(groupId));

export const getBrands = async () => await getQuery(TicketQueryList.getBrands());
export const getEventTemplates = async () => await getQuery(TicketQueryList.getEventTemplates());

export const getEmailPreview = async ({groupId, guestId}: GetEmailTemplateReq) =>
  await getQuery(TicketQueryList.getEmailPreview(groupId, guestId));

//share
export const getClientMagicLink = async ({body}: {body: ShareBodyT}) =>
  await postQuery(TicketQueryList.getClientMagicLink()).send(body);

export const getMagicLinkPreview = async (groupId: string) =>
  await postQuery(TicketQueryList.getPreviewMagicLink(groupId));
